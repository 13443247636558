import { IconButton, Stack } from "@mui/material";
import Skeleton from "@mui/material/Skeleton";
import { useState } from "react";

import { GetLifeDetailResponse } from "../../../../../../models";
import { useContractId } from "../../../../../context/ContractIDContext";
import { NumberToCZK } from "../../../../../functions";
import { useLifeDetail } from "../../../../../queryHooks";
import cs from "../../../../../translations/cs.json";
import SimpleDrawer from "../../../../Drawer/SimpleDrawer";
import InfoIcon from "../../../../Icons/Info";
import UmbrellaIcon from "../../../../Icons/Umbrella";
import { TriggerButton } from "../../../../Link";
import { BodyTextM } from "../../../../Typography";

export default function AccountValue(): JSX.Element {
	const id = useContractId();

	const result = useLifeDetail<GetLifeDetailResponse>({ id: id });
	const [isOpen, setIsOpen] = useState(false);

	return (
		<Stack sx={{ alignItems: "center" }} direction="row">
			<UmbrellaIcon sx={{ width: "2.4rem", height: "2.4rem" }} />
			<Stack
				ml={4}
				direction="row"
				sx={{
					flexGrow: 1,
					alignItems: "center",
				}}
			>
				<BodyTextM>{cs.life.investing.totalAccount}</BodyTextM>
				<BodyTextM sx={{ ml: 3 }}>
					{!result.isSuccess ? (
						<>
							<Skeleton width={70} sx={{ display: "inline-block" }} /> Kč
						</>
					) : (
						NumberToCZK(result.data.balanceTotal)
					)}
				</BodyTextM>
				<IconButton onClick={() => setIsOpen(true)}>
					<InfoIcon />
				</IconButton>
				<SimpleDrawer
					onClose={() => setIsOpen(false)}
					onOpen={() => setIsOpen(true)}
					open={isOpen}
				>
					<Stack spacing={5}>
						<BodyTextM>{cs.life.investing.totalFunds}</BodyTextM>
						<TriggerButton
							onClick={() => setIsOpen(false)}
							sx={{ alignSelf: "flex-end" }}
						>
							{cs.global.iUnderstand}
						</TriggerButton>
					</Stack>
				</SimpleDrawer>
			</Stack>
		</Stack>
	);
}
