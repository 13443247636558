import SvgIcon from "@mui/material/SvgIcon";

const HandCoinsIcon = (props): JSX.Element => {
	return (
		<SvgIcon width="32" height="30" viewBox="0 0 32 30" fill="none" {...props}>
			<path d="M25.926 14.731c1.75 0 3.173-1.407 3.173-3.138 0-1.73-1.423-3.138-3.173-3.138s-3.173 1.408-3.173 3.138 1.423 3.138 3.173 3.138Zm0-4.943c1.014 0 1.84.81 1.84 1.805 0 .995-.826 1.805-1.84 1.805-1.014 0-1.84-.81-1.84-1.805 0-.995.826-1.805 1.84-1.805ZM18.943 8.524c2.285 0 4.143-1.837 4.143-4.096 0-2.257-1.858-4.095-4.143-4.095-2.285 0-4.143 1.838-4.143 4.095 0 2.259 1.858 4.096 4.143 4.096Zm0-6.857c1.55 0 2.81 1.239 2.81 2.761 0 1.524-1.26 2.763-2.81 2.763s-2.81-1.24-2.81-2.763c0-1.522 1.26-2.761 2.81-2.761ZM16.983 17.822c1.866 0 3.383-1.501 3.383-3.345 0-1.845-1.517-3.345-3.383-3.345-1.865 0-3.382 1.5-3.382 3.345 0 1.844 1.517 3.345 3.382 3.345Zm0-5.356c1.13 0 2.05.902 2.05 2.01 0 1.11-.92 2.012-2.05 2.012s-2.049-.902-2.049-2.011c0-1.11.92-2.011 2.05-2.011ZM31.18 19.194c-1.355-.926-3.832.036-6.228.969-1.069.415-2.175.845-2.634.845-.025 0-.046.011-.07.014-.338-.232-.778-.412-1.343-.506a42.244 42.244 0 0 0-2.03-.271c-.541-.064-1.082-.126-1.62-.207a65.973 65.973 0 0 1-1.89-.322c-.652-.117-1.304-.234-1.98-.336-.8-.12-1.625-.248-2.469-.195-.516.03-1.077.282-1.668.749-2.748 2.165-5.986 3.4-8.79 4.324a.667.667 0 0 0 .417 1.265c2.915-.96 6.287-2.248 9.199-4.543.351-.277.695-.451.92-.464.705-.038 1.46.074 2.214.186.642.096 1.282.212 1.922.327.641.114 1.281.23 1.925.326.553.084 1.11.149 1.665.214.658.077 1.314.152 1.966.262.568.095.88.284.917.35.01.71-.142 1.194-.936 1.422-1.04.295-3.572.185-5.244.11-.626-.028-1.163-.052-1.523-.052a.668.668 0 0 0-.002 1.334c.347 0 .864.023 1.465.05 1.866.081 4.42.195 5.67-.16 1.25-.358 1.878-1.245 1.892-2.62.66-.14 1.506-.47 2.51-.86 1.68-.652 4.22-1.633 4.992-1.11.059.041.24.163.25.62-.265.696-3.849 3.122-10.24 6.44-.428.278-.87.387-1.48.343l-1.052-.066c-1.805-.115-3.67-.235-5.516-.247-1.519.01-3.713.145-5.455 1.594a.668.668 0 0 0 .852 1.026c1.07-.892 2.551-1.28 4.594-1.286 1.807.012 3.654.13 5.44.243l1.055.067c.898.058 1.618-.123 2.23-.522C32 22.855 32 21.452 32 20.99c0-1.02-.447-1.54-.82-1.797Z" />
		</SvgIcon>
	);
};

export default HandCoinsIcon;
