import Stack from "@mui/material/Stack";
import { createContext, ReactNode, useState } from "react";

import { PaymentFrequency } from "../../../../models";
import cs from "../../../translations/cs.json";
import HelpBox from "../../InfoBox/HelpBox";
import { DebtCtaPay } from "../../Link/CTA/PayById";
import { useDebtInformation } from "../../Link/CTA/useDebtInformation";
import { HeadingL } from "../../Typography";
import InsurancePriceBreakdownWithData from "./components/Transactions/InsurancePriceBreakdown";
import PaymentInfoWithData, {
	PaymentInfoCard,
} from "./components/Transactions/PaymentInfo";
import { TransactionsCardWithData } from "./components/Transactions/TransasctionComponent";

export const FrequencyContext = createContext(undefined);

interface ITransactions {
	debtCtaComponent?: ReactNode;
	transactionListComponent?: ReactNode;
	paymentInfoComponent?: ReactNode;
	priceBreakDownComponent?: ReactNode;
	inflationBreakDownComponent?: ReactNode;
}

const TransactionsTabView = ({
	debtCtaComponent,
	transactionListComponent,
	paymentInfoComponent,
	priceBreakDownComponent,
	inflationBreakDownComponent,
}: ITransactions) => {
	const [frequency, setFrequency] = useState(0);

	return (
		<>
			<Stack sx={{ gap: { md: 8, xxs: 7 } }}>
				<Stack gap={{ xxs: 7, md: 5 }}>
					{debtCtaComponent && debtCtaComponent}
					{transactionListComponent}
				</Stack>
				<Stack>
					<Stack sx={{ flexDirection: { md: "row" } }} gap={7}>
						<Stack sx={{ flexGrow: 1, maxWidth: { md: "50%" } }}>
							<HeadingL>Jak zaplatit příspěvek</HeadingL>
							<FrequencyContext.Provider value={{ frequency, setFrequency }}>
								<Stack gap={4}>
									{paymentInfoComponent}
									{priceBreakDownComponent}
								</Stack>
							</FrequencyContext.Provider>
						</Stack>
						{inflationBreakDownComponent}
					</Stack>
				</Stack>
				<HelpBox />
			</Stack>
		</>
	);
};

export const TransactionsTabLife = () => {
	const { data: debtStatus } = useDebtInformation();
	return (
		<TransactionsTabView
			transactionListComponent={<TransactionsCardWithData />}
			debtCtaComponent={debtStatus && <DebtCtaPay debtStatus={debtStatus} />}
			paymentInfoComponent={<PaymentInfoWithData />}
			priceBreakDownComponent={<InsurancePriceBreakdownWithData />}
		/>
	);
};

export const TransactionsTabFufi = () => {
	return (
		<TransactionsTabView
			transactionListComponent={<TransactionsCardWithData />}
			paymentInfoComponent={
				<PaymentInfoCard
					title={"Platební instrukce"}
					isLoading={false}
					hasQrCode={false}
					paymentInfo={{
						accountNumber: cs.global.notInNDP,
						amount: undefined as unknown as number,
						constantSymbol: cs.global.notInNDP,
						frequency: null as unknown as PaymentFrequency,
						iban: cs.global.notInNDP,
						nextPaymentDate: cs.global.notInNDP as unknown as Date,
						variableSymbol: cs.global.notInNDP,
					}}
				/>
			}
		/>
	);
};
