import { Box } from "@mui/material";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { graphql, useStaticQuery } from "gatsby";
import { getImage } from "gatsby-plugin-image";
import { BgImage } from "gbimage-bridge";
import { ReactNode } from "react";

import {
	GetLifeDetailResponse,
	GetLifeDetailV2Response,
} from "../../../../models";
import { useContractId } from "../../../context/ContractIDContext";
import { dateTimeFormat } from "../../../functions/dates";
import development from "../../../gatsby-theme-material-ui-top-layout/development-only";
import { useLifeDetail } from "../../../queryHooks";
import cs from "../../../translations/cs.json";
import HelpBox from "../../InfoBox/HelpBox";
import PayoutBox from "../../InfoBox/PayoutBox";
import InformationList from "../../InformationList";
import { DebtCtaPay } from "../../Link/CTA/PayById";
import { useDebtInformation } from "../../Link/CTA/useDebtInformation";
import InternalLink from "../../Link/InternalLink";
import Overview from "../../Overview";
import { HeadingL } from "../../Typography";
import useIsFunds from "../useIsFunds";
import AccountValue from "./components/AccountValue/index";

interface imageProps {
	umbrella: {
		publicURL: string;
	};
}

const imageQuery = (): imageProps => {
	return useStaticQuery<Queries.LifeContractDetailImagesQuery>(
		graphql`
			query LifeContractDetailImages {
				umbrella: file(relativePath: { eq: "umbrella.png" }) {
					publicURL
				}
				zoom: file(relativePath: { eq: "zoom.png" }) {
					childImageSharp {
						gatsbyImageData
					}
				}
			}
		`
	);
};

type ContractInformationProps = {
	signatureDate: Date | string | null;
	doesEmployerContribute: boolean;
	hasTaxBenefit: boolean;
	allowTaxBenefitChange: boolean;
	payoutVariant?: keyof typeof cs.life.contractDetail.payoutVariants;
	discountGroup?: string;
	discountPercent?: number;
};

interface IContractDetail {
	contractInformation?: ContractInformationProps;
	debtCta?: ReactNode;
	hasFunds?: boolean;
}

export const ContractDetailView = ({
	contractInformation,
	debtCta,
	hasFunds,
}: IContractDetail): JSX.Element => {
	const image = imageQuery();
	const id = useContractId();
	const theme = useTheme();
	const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
	const bgImage = getImage(imageQuery().zoom);

	return (
		<BgImage
			image={isDesktop ? bgImage : undefined}
			style={{
				backgroundPosition: "right 100px top 256px",
				backgroundRepeat: "no-repeat",
				backgroundSize: "250px 261px",
			}}
		>
			<Stack sx={{ gap: { md: 8, xxs: 7 } }}>
				{(hasFunds || (isDesktop && debtCta)) && (
					<Stack>
						{hasFunds && (
							<Overview
								image={image.umbrella.publicURL}
								alt="Podílový účet"
								sx={{
									mx: { md: -4, xxs: 0 },
									px: { md: 4 },
									background: { md: "none" },
									".MuiCardContent-root": {
										width: { md: "50%" },
									},
								}}
							>
								<AccountValue />
							</Overview>
						)}
						{isDesktop && debtCta}
					</Stack>
				)}
				<Stack sx={{ gap: { md: 8, xxs: 7 } }}>
					<Stack gap={7} sx={{ flexDirection: { md: "row" } }}>
						<Stack sx={{ flexGrow: 1, flexShrink: 0, maxWidth: { md: "50%" } }}>
							<HeadingL>Detail smlouvy</HeadingL>
							<InformationList
								isLoading={contractInformation === undefined}
								information={[
									{
										title: "Číslo smlouvy",
										description: id,
									},
									{
										title: "Příspěvek zaměstnavatele",
										description: contractInformation?.doesEmployerContribute
											? "Zaměstnavatel mi přispívá"
											: "Zaměstnavatel mi nepřispívá",
									},
									{
										title: "Datum uzavření",
										description: contractInformation?.signatureDate
											? dateTimeFormat.format(
													new Date(contractInformation.signatureDate)
											  )
											: "-",
									},
									contractInformation?.discountGroup !== undefined && {
										title: "Jsem součástí skupiny",
										description: contractInformation.discountGroup
											.split(",")
											.join(", "),
									},
									contractInformation?.discountPercent !== undefined && {
										title: "Skupinová sleva na pojištění",
										description: `${contractInformation.discountPercent} %`,
									},
									contractInformation?.payoutVariant !== undefined && {
										title: "Varianta plnění",
										// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
										description:
											cs.life.contractDetail.payoutVariants[
												contractInformation.payoutVariant
											],
									},
									{
										title: "Daňová odečitatelnost smlouvy",
										description: contractInformation?.hasTaxBenefit
											? "Smlouva je daňově odečitatelná."
											: "Smlouva není daňově odečitatelná.",
										additionalElements: [
											contractInformation?.allowTaxBenefitChange && (
												<InternalLink
													to="/"
													key="set-tax-deductibility"
													sx={{ mt: 2, ...development }}
												>
													Nastavit daňovou odečitatelnost
												</InternalLink>
											),
										],
									},
								]}
							/>
						</Stack>
						<PayoutBox sx={{ alignSelf: { md: "flex-end" } }} />
					</Stack>
					<HelpBox />
				</Stack>
			</Stack>
		</BgImage>
	);
};

export const getContractInormationFromV1 = (
	data: GetLifeDetailResponse | undefined
): ContractInformationProps | undefined => {
	if (!data?.contract) return;
	const {
		allowTaxBenefitChanges,
		hasTaxBenefit,
		doesEmployerContribute,
		signatureDate,
		discountGroup,
		discountPercent,
	} = data.contract;
	return {
		allowTaxBenefitChange: allowTaxBenefitChanges,
		hasTaxBenefit,
		doesEmployerContribute,
		signatureDate: signatureDate ?? null,
		discountGroup: discountGroup ? discountGroup : undefined,
		discountPercent: discountPercent ? discountPercent : undefined,
		payoutVariant: data.payoutVariant
			? (data.payoutVariant.toString() as keyof typeof cs.life.contractDetail.payoutVariants)
			: undefined,
	};
};

export const getContractInormationFromV2 = (
	data: GetLifeDetailV2Response | undefined
): ContractInformationProps | undefined => {
	if (!data) return undefined;
	return {
		signatureDate: data.dateSignature ?? null,
		allowTaxBenefitChange: false,
		doesEmployerContribute: data.doesEmployerContribute,
		hasTaxBenefit: data.hasTaxAdvantage,
		discountGroup: cs.global.notInNDP,
		discountPercent: cs.global.notInNDP as unknown as number,
		payoutVariant: "99",
	};
};

export const ContractDetailTabFufi = () => {
	const contractId = useContractId();
	const { data } = useLifeDetail<GetLifeDetailV2Response>({
		id: contractId,
	});

	const debtCta = (
		<Box sx={{ maxWidth: "50%" }}>
			<DebtCtaPay
				debtStatus={{
					accountNumber: cs.global.notInNDP,
					constantSymbol: cs.global.notInNDP,
					debtAmmount: "Nedpolatek chybí v NDP api",
					debtMessage: cs.global.notInNDP,
					variableSymbol: cs.global.notInNDP,
				}}
			/>
		</Box>
	);

	return (
		<ContractDetailView
			contractInformation={getContractInormationFromV2(data)}
			debtCta={debtCta}
			hasFunds={false}
		/>
	);
};

export const ContractDetailTabLife = () => {
	const contractId = useContractId();
	const { data } = useLifeDetail<GetLifeDetailResponse>({
		id: contractId,
	});
	const { data: debtStatus, isSuccess: debtSuccess } = useDebtInformation();
	const hasFunds = useIsFunds(contractId);
	return (
		<ContractDetailView
			debtCta={
				debtSuccess && debtStatus ? (
					<Box sx={{ maxWidth: "50%" }}>
						<DebtCtaPay debtStatus={debtStatus} />
					</Box>
				) : undefined
			}
			contractInformation={getContractInormationFromV1(data)}
			hasFunds={hasFunds}
		/>
	);
};
