import SvgIcon from "@mui/material/SvgIcon";

const UmbrellaIcon = (props): JSX.Element => {
	return (
		<SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
			<path d="M8.6 19.3c-.6-.4-1.4-.4-2 0a2 2 0 0 1-1 .2c-.7 0-1-.1-1.1-.2-.5-.4-1-.5-1.5-.4-2.4.5-3 2.2-3 4.6 0 .3.2.5.5.5s.5-.2.5-.5c0-2.4.6-3.3 2.2-3.6.2 0 .5 0 .7.2.3.2.7.4 1.6.4.8 0 1.2-.2 1.5-.3h.1c.3-.3.7-.3 1 0 1 .6 1.4 1.4 1.6 3.3 0 .3.2.5.5.5s.5-.3.5-.5c-.2-2.2-.7-3.3-2-4.2Zm-3.2-1.2c1.7 0 3-2.4 3-4a3 3 0 0 0-3-2.8 3 3 0 0 0-3 2.9c0 1.5 1.3 4 3 4Zm0-5.8a2 2 0 0 1 2 1.9c0 1.1-1 3-2 3s-2-1.9-2-3c0-1 .9-2 2-2Zm16.6 7a2 2 0 0 0-2.1 0 2 2 0 0 1-1 .2c-.7 0-1-.1-1.1-.2-.5-.4-1-.5-1.4-.4-2.5.5-3 2.2-3 4.6 0 .3.1.5.4.5.3 0 .5-.2.5-.5 0-2.4.6-3.3 2.3-3.6.2 0 .4 0 .6.2.4.2.8.4 1.6.4s1.2-.2 1.5-.3h.1c.3-.3.7-.3 1 0 1.1.6 1.5 1.4 1.6 3.3 0 .3.2.5.5.5s.5-.3.5-.5c-.1-2.2-.7-3.3-2-4.2Zm-6.3-5.1c0 1.5 1.3 4 3 4s3-2.5 3-4a3 3 0 0 0-3-3 3 3 0 0 0-3 3Zm5 0c0 1.2-1 3-2 3s-2-1.9-2-3c0-1 1-2 2-2a2 2 0 0 1 2 2Z" />
			<path d="M16.7 9h.3c2-.7 4.3-1 6.4-.7.2 0 .4 0 .5-.2v-.5A12.5 12.5 0 0 0 12.2 0C6.8 0 2 3 0 7.6a.5.5 0 0 0 .6.7h1.6A11.1 11.1 0 0 1 7 9h.2a12 12 0 0 1 4.2-.9v10.1a.5.5 0 1 0 1 0v-10c1.4 0 2.8.2 4.2.8ZM7 8a12 12 0 0 0-5.7-.7C3.3 3.4 7.3 1 12 1a12 12 0 0 1 10.6 6.2c-2-.1-4 .2-5.8.9a13.2 13.2 0 0 0-9.7 0Z" />
		</SvgIcon>
	);
};

export default UmbrellaIcon;
