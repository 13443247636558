import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { Stack } from "@mui/material";
import useIsDesktop from "desktop/useIsDesktop";
import { StaticImage } from "gatsby-plugin-image";
import { Button } from "gatsby-theme-material-ui";
import { useState } from "react";

import {
	Address,
	AddressType,
	Contacts,
	NdpInsuredPerson,
} from "../../../../../../models";
import { dateTimeFormat } from "../../../../../functions/dates";
import { useCountry } from "../../../../../hooks/useCountry";
import cs from "../../../../../translations/cs.json";
import AddressTemplate from "../../../../AddressBox/AddressTemplate";
import InfoIconDrawer from "../../../../Drawer/InfoIconDrawer";
import Modal from "../../../../Drawer/Modal";
import NotInMVP, { NotInMVPButtonLink } from "../../../../Drawer/NotInMVP";
import InformationList from "../../../../InformationList";
import { BodyTextM, HeadingL } from "../../../../Typography";

const notInNDPadrress: Address = {
	street: cs.global.notInNDP,
	country: "",
	houseNumber: "",
	note: "",
	town: "",
	zip: "",
	type: AddressType.Unknown,
};

interface ClientSharedProps {
	person?: NdpInsuredPerson;
	isLoading: boolean;
	contacts?: Contacts;
	contactAddress?: Address;
	permanentAddress?: Address;
}

export const ClientFufiView = ({
	person,
	contacts,
	isLoading,
	contactAddress,
	permanentAddress,
}: ClientSharedProps) => {
	const [isDrawerOpen, setIsDrawerOpen] = useState(false);

	const ai = useAppInsightsContext();

	const handleClick = (changeText: string) => {
		ai.trackEvent({
			name: `[Change] ${changeText}`,
			properties: {
				type: "not in MVP",
			},
		});
	};

	const isDesktop = useIsDesktop();

	const contactInfoItems = [
		contacts?.phone && {
			title: cs.global.phone,
			description: contacts.phone,
		},
		contacts?.phoneAdditional && {
			title: `${cs.global.phone} 2`,
			description: contacts.phoneAdditional,
		},
		contacts?.email && {
			title: cs.global.email,
			description: contacts.email,
		},
	].filter((item) => typeof item === "object" && item !== null);

	const citizenshipLong = useCountry(
		person?.person.citizenship
	)?.country_name_cz_long;

	return (
		<>
			<Stack
				spacing={isDesktop ? 0 : 5}
				sx={{
					flexDirection: { md: "row" },
					alignItems: { md: "flex-end" },
					mt: { md: 7 },
				}}
			>
				<Stack
					spacing={5}
					sx={{
						width: { md: "50%" },
						mr: { md: 4 },
					}}
				>
					<HeadingL withoutScale>Osobní informace</HeadingL>
					<InformationList
						sx={{ mb: 5 }}
						isLoading={isLoading}
						title="Osobní údaje"
						action={
							<NotInMVP
								onClick={() => {
									handleClick("Osobní údaje");
								}}
							/>
						}
						information={[
							{
								title: "Titul před jménem",
								description: person?.person.titlePrefix ?? "-",
							},
							{
								title: "Jméno",
								description: person?.person.firstName ?? "-",
							},
							{
								title: "Příjmení",
								description: person?.person.lastName ?? "-",
							},
							{
								title: "Titul za jménem",
								description: person?.person.titleSuffix ?? "-",
							},
							{
								title: "Datum narození",
								description: person?.person.birthDate
									? dateTimeFormat.format(new Date(person.person.birthDate))
									: "-",
							},
							{
								title: <>Rodné číslo (číslo&nbsp;pojištěnce)</>,
								description: person?.person.birthNumber ?? "-",
							},
							{
								title: "Státní občanství",
								description: citizenshipLong ?? "-",
							},
						]}
					/>
					<InformationList
						isLoading={isLoading}
						title={cs.global.permanentResidence}
						action={
							<Button
								sx={{ alignSelf: "flex-start", p: 0 }}
								onClick={() => {
									setIsDrawerOpen(true);
								}}
								disabled
							>
								<BodyTextM>{cs.global.change}</BodyTextM>
							</Button>
						}
						information={[
							{
								title: "Adresa",
								description: (
									<AddressTemplate
										address={permanentAddress ?? notInNDPadrress}
									/>
								),
							},
						]}
					/>
					<Modal
						open={isDrawerOpen}
						onClose={() => {
							setIsDrawerOpen(false);
						}}
					>
						<InfoIconDrawer
							title={cs.global.permanentResidence}
							description={
								<span
									dangerouslySetInnerHTML={{
										__html: cs.profile.person.lifePermanentAddressInfo,
									}}
								></span>
							}
							bottomButtonAction={() => {
								setIsDrawerOpen(false);
							}}
						/>
					</Modal>
				</Stack>
				<Stack sx={{ width: { md: "50%" }, ml: { md: 4 } }}>
					{isDesktop && (
						<StaticImage
							src="../../images/passport.png"
							alt="passport"
							height={180}
							objectFit="contain"
						/>
					)}
					<Stack
						sx={{
							flexDirection: { md: "column-reverse" },
							gap: { md: 5, xs: 0 },
						}}
					>
						<Stack spacing={4}>
							<InformationList
								isLoading={isLoading}
								title="Kontaktní adresa"
								action={
									<NotInMVPButtonLink
										sx={{ alignSelf: "flex-start" }}
										text={cs.changeAddress.changeContactAddress}
										onClick={() =>
											handleClick(cs.profile.person.changeContactAddress)
										}
									/>
								}
								information={[
									{
										title: "Adresa",
										description: (
											<AddressTemplate
												address={contactAddress ?? notInNDPadrress}
											/>
										),
									},
								]}
							/>
						</Stack>
						<Stack spacing={4}>
							{contactInfoItems.length !== 0 && (
								<InformationList
									isLoading={isLoading}
									title="Kontaktní údaje"
									action={
										<NotInMVP
											onClick={() => {
												handleClick("Kontaktní údaje");
											}}
										/>
									}
									sx={{ mt: { md: 0, xxs: 5 } }}
									information={contactInfoItems}
								/>
							)}
						</Stack>
					</Stack>
				</Stack>
			</Stack>
		</>
	);
};
