import { BackNavigationBar } from "../../AppBar";
import { TabsWithQueryParameter } from "../../Tabs";
import { FufiSubtitle } from "../Heading/Subtitle";
import { FufiTitle } from "../Heading/Title";
import { ClientTabFufi } from "../Tabs/Client";
import { ContractDetailTabFufi } from "../Tabs/ContractDetail";
import InsuredEvent from "../Tabs/InsuredEvent";
import { PersonsTabFufi } from "../Tabs/Persons";
import { TransactionsTabFufi } from "../Tabs/Transactions";

export const FufiInsuranceDetail = () => {
	return (
		<TabsWithQueryParameter
			appBar={
				<BackNavigationBar title={<FufiTitle />} subtitle={<FufiSubtitle />} />
			}
			queryParamKey="main-tab"
			tabs={[
				{ label: "Smlouva", slug: "smlouva" },
				{ label: "Osoby a krytí", slug: "osoby-kryti" },
				{ label: "Platby", slug: "pohyby" },
				{ label: "Klient", slug: "klient" },
				{ label: "Pojistná událost", slug: "pojistna-udalost" },
			]}
		>
			<ContractDetailTabFufi />
			<PersonsTabFufi />
			<TransactionsTabFufi />
			<ClientTabFufi />
			<InsuredEvent />
		</TabsWithQueryParameter>
	);
};
