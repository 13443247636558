import { Box, Paper } from "@mui/material";
import { BoxProps } from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";

import { NativeSelectInput } from "../../../Form/Select";
import { BodyTextM } from "../../../Typography";
import { CommonPersonalDetails } from "../../types";
import { useSelectedPerson } from "./Persons/SelectedPersonContext";

interface ISelectPerson extends Pick<BoxProps, "sx"> {
	persons?: CommonPersonalDetails[];
	isLoading: boolean;
}

export default function SelectPerson({
	sx,
	persons,
	isLoading,
}: ISelectPerson): JSX.Element {
	const { selectedPersonIndex, setSelectedPerson } = useSelectedPerson();

	const title =
		!isLoading &&
		persons &&
		(() => {
			if (persons.length === 1) return "Na smlouvě je pojištěna jedna osoba.";
			else if (persons.length > 1 && persons.length <= 4)
				return `Na smlouvě jsou pojištěny ${persons.length} osoby. Pro kterou osobu chcete zobrazit data?`;
			else
				return `Na smlouvě je pojištěno ${persons.length} osob. Pro kterou osobu chcete zobrazit data?`;
		})();

	return (
		<Box sx={{ width: { md: "50%" }, ...sx }}>
			<Paper sx={{ mr: { md: 4 } }}>
				<Stack
					sx={{
						p: 4,
					}}
					gap={4}
				>
					{isLoading ? (
						<Skeleton variant="text" />
					) : (
						<BodyTextM>{title}</BodyTextM>
					)}

					<NativeSelectInput
						name="insuredPerson"
						isRequired={true}
						label="Vyberte pojištěnou osobu"
						isLoading={isLoading}
						value={persons ? selectedPersonIndex : null}
						onChange={(event) => {
							setSelectedPerson(parseInt(event.target.value));
						}}
					>
						{persons?.map((person, index) => {
							const personDescription = (() => {
								return [
									person.firstName,
									person.lastName,
									person.birthDate && new Date(person.birthDate).getFullYear(),
									person.roleDescription,
								]
									.filter((string) => !!string)
									.join(" ");
							})();
							return (
								<option value={index} key={person.birthNumber ?? index}>
									{personDescription}
								</option>
							);
						})}
					</NativeSelectInput>
				</Stack>
			</Paper>
		</Box>
	);
}
