import { Stack } from "@mui/material";
import { useState } from "react";

import { useContractId } from "../../../context/ContractIDContext";
import { useLifeDocuments } from "../../../queryHooks";
import DocumentManager from "../../DocumentManager";
import DocumentsError from "../../DocumentManager/DocumentsError";
import { ErrorBoundaryWithAsyncContext } from "../../ErrorBoundary";
import HelpBox from "../../InfoBox/HelpBox";

export function Docs(): JSX.Element {
	const [docsEndpointErrorCode, setDocsEndpointErrorCode] = useState();

	const id = useContractId();
	const result = useLifeDocuments({
		id: id,
		options: {
			onError: (error) => setDocsEndpointErrorCode(error.errorResponse.code),
		},
	});

	if (result.isSuccess) return <DocumentManager data={result.data} />;

	if (result.isError)
		return <DocumentsError errorCode={docsEndpointErrorCode} />;

	return <DocumentManager isLoading />;
}

const DocumentTab = () => {
	return (
		<ErrorBoundaryWithAsyncContext>
			<Stack>
				<Docs />
				<HelpBox />
			</Stack>
		</ErrorBoundaryWithAsyncContext>
	);
};

export default DocumentTab;
