import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { Button } from "@mui/material";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { useContext, useEffect, useState } from "react";

import {
	GetLifeDetailResponse,
	PriceItem,
	PriceItemType,
} from "../../../../../../models";
import { useContractId } from "../../../../../context/ContractIDContext";
import { NumberToCZK } from "../../../../../functions";
import { useLifeDetail } from "../../../../../queryHooks";
import cs from "../../../../../translations/cs.json";
import SimpleDrawer from "../../../../Drawer/SimpleDrawer";
import InformationList from "../../../../InformationList";
import { HeadingL } from "../../../../Typography";
import { FrequencyContext } from "../../Transactions";

const frequenciesRemap = {
	Měsíční: "měsíčně",
	Kvartální: "kvartálně",
	Půroční: "půlročně",
	Roční: "ročně",
	"Za vlastní období": "za vlastní období",
};

const InsurancePriceBreakdownWithData = (): JSX.Element => {
	const id = useContractId();
	const result = useLifeDetail<GetLifeDetailResponse>({
		id: id,
		options: {
			placeholderData: {
				priceBreakdown: [],
			},
		},
	});
	const { priceBreakdown } = result.data;

	const mainInsurance = priceBreakdown.find(
		(price: PriceItem) => price.type === PriceItemType.MainRider
	);

	const supplementaryInsurance = priceBreakdown.filter(
		(price: PriceItem) => price.type === PriceItemType.Rider
	);

	const insuranceDiscounts = priceBreakdown.filter(
		(price: PriceItem) => price.type === PriceItemType.Discount
	);

	const insuranceSurcharges = priceBreakdown.filter(
		(price: PriceItem) => price.type === PriceItemType.Surcharge
	);

	const { frequency } = useContext(FrequencyContext);

	const [isOpen, setIsOpen] = useState(false);
	const appInsights = useAppInsightsContext();

	useEffect(() => {
		if (!mainInsurance)
			appInsights.trackEvent(
				{ name: "Main rider priceBreakdown is missing" },
				{ contractId: id }
			);
	}, [mainInsurance, id]);

	return (
		<>
			<Button
				variant="text"
				isHorizontal
				size="small"
				sx={{ alignSelf: "flex-start" }}
				onClick={() => setIsOpen((oldValue) => !oldValue)}
			>
				Detailní složení pojistného
			</Button>
			<SimpleDrawer
				open={isOpen}
				onClose={() => setIsOpen(false)}
				onOpen={() => setIsOpen(true)}
				title={cs.global.detailedCompositionOfInsurance}
				isCloseIcon
			>
				<HeadingL>{cs.life.transactions.insuranceAmount}</HeadingL>
				<Stack spacing={5}>
					{mainInsurance && (
						<InformationList
							title={cs.life.global.mainInsurance}
							information={[
								{
									title: mainInsurance.name,
									additionalElements: [
										<Box sx={{ color: "success.main" }} key="mainInsurance">
											{NumberToCZK(mainInsurance.amount)}{" "}
											{frequenciesRemap[frequency]}
										</Box>,
									],
								},
							]}
						/>
					)}
					{supplementaryInsurance.length !== 0 && (
						<InformationList
							title={cs.life.global.supplementaryInsurance}
							information={supplementaryInsurance.map((insurance, index) => {
								return {
									title: insurance.name,
									additionalElements: [
										<Box sx={{ color: "success.main" }} key={index}>
											{NumberToCZK(insurance.amount)}{" "}
											{frequenciesRemap[frequency]}
										</Box>,
									],
								};
							})}
						/>
					)}
					{insuranceDiscounts.length !== 0 && (
						<InformationList
							title={cs.life.global.insuranceDiscounts}
							information={insuranceDiscounts.map((insurance, index) => {
								return {
									title: insurance.name,
									additionalElements: [
										<Box sx={{ color: "success.main" }} key={index}>
											{NumberToCZK(insurance.amount)}{" "}
											{frequenciesRemap[frequency]}
										</Box>,
									],
								};
							})}
						/>
					)}
					{insuranceSurcharges.length !== 0 && (
						<InformationList
							title={cs.life.global.insuranceSurcharges}
							information={insuranceSurcharges.map((insurance, index) => {
								return {
									title: insurance.name,
									additionalElements: [
										<Box sx={{ color: "success.main" }} key={index}>
											{NumberToCZK(insurance.amount)}{" "}
											{frequenciesRemap[frequency]}
										</Box>,
									],
								};
							})}
						/>
					)}
				</Stack>
			</SimpleDrawer>
		</>
	);
};

export default InsurancePriceBreakdownWithData;
