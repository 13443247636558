import { PageProps } from "gatsby";

import { ProductType } from "../../../models";
import { SEO } from "../../components/SEO";
import { FufiInsuranceDetail } from "../../components/ZivotniPojisteni/FufiPage/Root";
import { LifeInsuranceDetail } from "../../components/ZivotniPojisteni/LifePage/Root";
import {
	ContractIDProvider,
	extractContractIDFromPageProps,
} from "../../context/ContractIDContext";
import { ContractTypeProvider } from "../../context/ContractTypeContext";
import withProtection from "../../highOrderComponents/withProtection";
import { useContractById } from "../../queryHooks";
import cs from "../../translations/cs.json";
import { assertIsLifeContractType } from "../../types/contracts";

const detailByType = {
	[ProductType.Clf]: FufiInsuranceDetail,
	[ProductType.Cl]: LifeInsuranceDetail,
};

const LifeInsuranceDetailPage = (pageProps: PageProps): JSX.Element => {
	const contractId = extractContractIDFromPageProps(pageProps);
	const { data, isSuccess } = useContractById({ id: contractId });

	if (!isSuccess) return <>{cs.global.pageIsLoading}</>;

	assertIsLifeContractType(
		data.type,
		`Unsupported product type in LifeInsuranceDetailPage: ${data.type}`
	);

	const Detail = detailByType[data.type];

	return (
		<ContractIDProvider contractId={contractId}>
			<ContractTypeProvider contractType={data.type}>
				<Detail />
			</ContractTypeProvider>
		</ContractIDProvider>
	);
};

export default withProtection(LifeInsuranceDetailPage);

export const Head = (): JSX.Element => (
	<SEO title="Životko">
		<meta name="robots" content="noindex" />
	</SEO>
);
