import { Stack } from "@mui/material";

import {
	GetLifeDetailResponse,
	GetLifeDetailV2Response,
	InsuredPerson,
} from "../../../../models";
import { useContractId } from "../../../context/ContractIDContext";
import { useLifeDetail } from "../../../queryHooks";
import ClientShared from "../../DPS/ClientShared";
import HelpBox from "../../InfoBox/HelpBox";
import {
	mapCommonPeronalDetailFromInsuredPerson,
	mapCommonPeronalDetailFromNdpInsuredPerson,
	mapNdpAdressToAdress,
} from "../types";
import { ClientFufiView } from "./components/Client";
import {
	SelectedPersonContextProvider,
	useSelectedPerson,
} from "./components/Persons/SelectedPersonContext";
import SelectPerson from "./components/SelectPerson";

const ClientTabLifeWithData = () => {
	const id = useContractId();

	const result = useLifeDetail<GetLifeDetailResponse>({ id: id });

	const { selectedPersonIndex } = useSelectedPerson();

	const { insuredPersons } = result.isSuccess
		? result.data
		: {
				insuredPersons: [
					{
						degreeBefore: undefined,
						degreeAfter: undefined,
						firstName: undefined,
						lastName: undefined,
						dateOfBirth: undefined,
						birthNumber: undefined,
						citizenship: undefined,
						role: undefined,
						permanentAddress: {
							street: undefined,
							houseNumber: undefined,
							town: undefined,
							zip: undefined,
							country: undefined,
						},
						contactAddress: {
							street: undefined,
							houseNumber: undefined,
							town: undefined,
							zip: undefined,
							country: undefined,
						},
						contact: {
							email: undefined,
							phone1: undefined,
						},
					},
				],
		  };

	const {
		dateOfBirth,
		birthNumber,
		degreeBefore,
		degreeAfter,
		firstName,
		lastName,
		...insuredPerson
	} = insuredPersons[selectedPersonIndex];

	return (
		<>
			<SelectPerson
				persons={insuredPersons.map((person) =>
					mapCommonPeronalDetailFromInsuredPerson(person as InsuredPerson)
				)}
				isLoading={!result.isSuccess}
			/>
			<ClientShared
				person={{
					...insuredPerson,
					clientInfo: {
						dateOfBirth,
						birthNumber,
						degreeBefore,
						degreeAfter,
						firstName,
						lastName,
					},
				}}
				isSuccess={result.isSuccess}
				isLoading={result.isLoading}
			/>
			<HelpBox />
		</>
	);
};

const ClientTabFufiWithData = () => {
	const contractId = useContractId();
	const { data, isSuccess } = useLifeDetail<GetLifeDetailV2Response>({
		id: contractId,
	});

	const { selectedPersonIndex } = useSelectedPerson();

	const selectedPerson = data?.insuredPersons[selectedPersonIndex];

	const isOwner =
		(isSuccess && data.owner && selectedPerson?.person.id === data.owner.id) ===
		true;

	return (
		<>
			<Stack>
				<SelectPerson
					persons={
						isSuccess
							? data.insuredPersons.map((person) =>
									mapCommonPeronalDetailFromNdpInsuredPerson(person, isOwner)
							  )
							: []
					}
					isLoading={!isSuccess}
				/>
				<ClientFufiView
					isLoading={!isSuccess}
					person={selectedPerson}
					contacts={isOwner ? data.owner?.contacts : undefined}
					contactAddress={
						isOwner && data.owner?.contactAddress
							? mapNdpAdressToAdress(data.owner.contactAddress)
							: undefined
					}
					permanentAddress={
						isOwner && data.owner?.permanentAddress
							? mapNdpAdressToAdress(data.owner.permanentAddress)
							: undefined
					}
				/>
			</Stack>
			<HelpBox />
		</>
	);
};

export const ClientTabLife = () => {
	return (
		<SelectedPersonContextProvider>
			<ClientTabLifeWithData />
		</SelectedPersonContextProvider>
	);
};

export const ClientTabFufi = () => {
	return (
		<SelectedPersonContextProvider>
			<ClientTabFufiWithData />
		</SelectedPersonContextProvider>
	);
};
