import Stack from "@mui/material/Stack";
import useIsDesktop from "desktop/useIsDesktop";
import { graphql, useStaticQuery } from "gatsby";
import { getImage } from "gatsby-plugin-image";
import { BgImage } from "gbimage-bridge";

import {
	GetLifeDetailResponse,
	GetLifeDetailV2Response,
} from "../../../../models";
import { useContractId } from "../../../context/ContractIDContext";
import { useLifeDetail } from "../../../queryHooks";
import cs from "../../../translations/cs.json";
import HelpBox from "../../InfoBox/HelpBox";
import { BodyTextM, HeadingL } from "../../Typography";
import {
	CommonLifeContractPerson,
	mapCommonPeronalDetailFromInsuredPerson,
	mapCommonPeronalDetailFromNdpInsuredPerson,
	mapFromV1Beneficiary,
	mapFromV1RiderToCommonCoverage,
	mapFromV2BeneficiaryParty,
	mapFromV2CoverageToCommonCoverage,
} from "../types";
import Beneficiaries from "./components/Persons/Beneficiaries";
import { MainRiderView } from "./components/Persons/MainRiderWithData";
import { SecondaryRidersView } from "./components/Persons/SecondaryRidersWithData";
import {
	SelectedPersonContextProvider,
	useSelectedPerson,
} from "./components/Persons/SelectedPersonContext";
import SelectPerson from "./components/SelectPerson";

const umbrellaImage = () => {
	return useStaticQuery<Queries.UmbrelaImageQuery>(
		graphql`
			query UmbrelaImage {
				umbrella: file(relativePath: { eq: "umbrella-bg.png" }) {
					childImageSharp {
						gatsbyImageData
					}
				}
			}
		`
	);
};

interface IPersons {
	persons?: CommonLifeContractPerson[];
	isLoading: boolean;
}

export const PersonsTabView = ({ persons, isLoading }: IPersons) => {
	const { selectedPersonIndex } = useSelectedPerson();

	const selectedPerson = persons && persons[selectedPersonIndex];

	const isDesktop = useIsDesktop();

	const image = getImage(umbrellaImage().umbrella);

	return (
		<>
			<BgImage
				image={isDesktop ? image : null}
				style={{
					backgroundPosition: "right top",
					backgroundRepeat: "no-repeat",
					backgroundSize: "350px 365px",
				}}
			>
				<Stack sx={{ gap: { md: 8, xxs: 7 } }}>
					<SelectPerson
						persons={persons?.map((person) => person.personalDetails)}
						isLoading={isLoading}
					/>
					{isLoading || selectedPerson?.personalDetails.hasRiders ? (
						<Stack>
							<HeadingL withoutScale>
								{cs.life.personsAndRiders.selected.title}
							</HeadingL>
							<Stack gap={5}>
								{(isLoading || selectedPerson?.mainCovegare) && (
									<MainRiderView
										mainCoverage={selectedPerson?.mainCovegare}
										isLoading={isLoading}
									/>
								)}
								{(isLoading ||
									(selectedPerson?.otherCoverages &&
										selectedPerson.otherCoverages.length > 0)) && (
									<SecondaryRidersView
										isLoading={isLoading}
										secondaryCoverages={selectedPerson?.otherCoverages}
									/>
								)}
							</Stack>
						</Stack>
					) : (
						<BodyTextM>{cs.life.persons.noCoveragesOrBeneficiaries}</BodyTextM>
					)}
					{selectedPerson && !selectedPerson.personalDetails.isChild && (
						<Beneficiaries person={selectedPerson} isLoading={isLoading} />
					)}
					<HelpBox />
				</Stack>
			</BgImage>
		</>
	);
};

export const PersonsTabLife = () => {
	const contractId = useContractId();
	const { data, isSuccess } = useLifeDetail<GetLifeDetailResponse>({
		id: contractId,
	});

	return (
		<SelectedPersonContextProvider>
			<PersonsTabView
				isLoading={!isSuccess}
				persons={isSuccess ? mapInsuredPersonsFromV1Detail(data) : undefined}
			/>
		</SelectedPersonContextProvider>
	);
};

export const PersonsTabFufi = () => {
	const contractId = useContractId();
	const { data, isSuccess } = useLifeDetail<GetLifeDetailV2Response>({
		id: contractId,
	});

	return (
		<SelectedPersonContextProvider>
			<PersonsTabView
				isLoading={!isSuccess}
				persons={isSuccess ? mapInsuredPersonsFromV2Detail(data) : undefined}
			/>
		</SelectedPersonContextProvider>
	);
};

const mapInsuredPersonsFromV1Detail = (
	detail: GetLifeDetailResponse
): CommonLifeContractPerson[] => {
	return detail.insuredPersons.map((person) => {
		const commonCoverages = (person.riders ?? []).map(
			mapFromV1RiderToCommonCoverage
		);

		return {
			personalDetails: mapCommonPeronalDetailFromInsuredPerson(person),
			mainCovegare:
				commonCoverages.find((coverage) => coverage.isMain) ?? undefined,
			otherCoverages:
				commonCoverages.filter((coverage) => !coverage.isMain) ?? undefined,
			beneficiaries: person.beneficiaries.map(mapFromV1Beneficiary),
		};
	});
};

const mapInsuredPersonsFromV2Detail = (
	detail: GetLifeDetailV2Response
): CommonLifeContractPerson[] => {
	return detail.insuredPersons.map((person) => {
		const isOwner =
			(detail.owner?.id && person.person.id === detail.owner.id) === true;
		const coverages = person.coverages.map(mapFromV2CoverageToCommonCoverage);

		return {
			personalDetails: mapCommonPeronalDetailFromNdpInsuredPerson(
				person,
				isOwner
			),
			mainCovegare: coverages.find((coverage) => coverage.isMain),
			otherCoverages: coverages.filter((coverage) => !coverage.isMain),
			beneficiaries: person.beneficiaries.map(mapFromV2BeneficiaryParty),
		};
	});
};
