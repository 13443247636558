import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import Stack from "@mui/material/Stack";
import { StaticImage } from "gatsby-plugin-image";

import { NotInMVPButton } from "../../Drawer/NotInMVP";
import HelpBox from "../../InfoBox/HelpBox";
import { InternalLink } from "../../Link";
import { HeadingL } from "../../Typography";

function InsuredEvent(): JSX.Element {
	const ai = useAppInsightsContext();
	const handleClick = (changeText: string) => {
		ai.trackEvent({
			name: `[Change] ${changeText}`,
			properties: {
				type: "not in MVP",
			},
		});
	};

	return (
		<>
			<Stack
				direction={{ xxs: "column", md: "row-reverse" }}
				sx={{ alignSelf: { md: "flex-start" } }}
				spacing={5}
			>
				<StaticImage
					src="../../images/insured-event.png"
					alt="insured event"
					height={223}
					layout="fixed"
					style={{ alignSelf: "center" }}
				/>
				<Stack>
					<HeadingL withoutScale>Hlášení pojistné události</HeadingL>
					<NotInMVPButton
						text="Nahlásit pojistnou událost"
						onClick={() => {
							handleClick("Nahlásit pojistnou událost");
						}}
						content={
							(
								<>
									Usilovně pracujeme na tom, abyste mohli nahlásit pojistnou
									událost přes Portál. Zatím ale ještě nemáme hotovo. Změnu
									můžete prozatím provést jako doposud na adrese{" "}
									<InternalLink
										variant="light"
										target="_blank"
										to="https://www.nn.cz/pro-klienty/pojisteni/pojistna-udalost/hlaseni-pojistne-udalosti.html"
									>
										https://www.nn.cz/pro-klienty/pojisteni/pojistna-udalost/hlaseni-pojistne-udalosti.html
									</InternalLink>
								</>
							) as unknown as string
						}
					/>
				</Stack>
			</Stack>
			<HelpBox />
		</>
	);
}
export default InsuredEvent;
