import Stack from "@mui/material/Stack";

import { NumberToCZK } from "../../../../../functions";
import { dateTimeFormat } from "../../../../../functions/dates";
import cs from "../../../../../translations/cs.json";
import InformationList from "../../../../InformationList";
import { Props as InformationListProps } from "../../../../InformationList/index";
import { BodyTextM } from "../../../../Typography";
import { CommonCoverage } from "../../../types";
import { useSelectedPerson } from "./SelectedPersonContext";
import ShowRiderDetailButton from "./ShowRiderDetailButton";

interface IMainRiderWithData
	extends Pick<InformationListProps, "isLoading" | "sx"> {
	mainCoverage?: CommonCoverage;
}

export const MainRiderView = ({
	mainCoverage,
	isLoading,
}: IMainRiderWithData): JSX.Element => {
	const { selectedPersonIndex } = useSelectedPerson();

	return (
		<InformationList
			isLoading={isLoading}
			title={cs.life.global.mainInsurance}
			sx={{ flexGrow: { md: 1 } }}
			information={[
				{
					title: mainCoverage?.name,
					description: `${
						mainCoverage?.validFrom
							? dateTimeFormat.format(new Date(mainCoverage.validFrom))
							: "-"
					} - ${
						mainCoverage?.validTo
							? dateTimeFormat.format(new Date(mainCoverage.validTo))
							: "-"
					}`,
					additionalElements: (
						<Stack
							sx={{
								flexDirection: { md: "row" },
								justifyContent: { md: "space-between" },
								width: { md: "250px" },
								pl: { md: 3 },
							}}
						>
							<BodyTextM sx={{ color: "success.main" }}>
								{mainCoverage && NumberToCZK(mainCoverage.ammount)}
							</BodyTextM>
							{mainCoverage && (
								<ShowRiderDetailButton
									coverageId={mainCoverage.id}
									coverageName={mainCoverage.name}
									filePath={mainCoverage.documentPath}
								/>
							)}
						</Stack>
					),
				},
			]}
		/>
	);
};
