import { useContractId } from "../../../context/ContractIDContext";
import { BackNavigationBar } from "../../AppBar";
import { TabsWithQueryParameter } from "../../Tabs";
import { LifeSubtitle } from "../Heading/Subtitle";
import { LifeTitle } from "../Heading/Title";
import { ClientTabLife } from "../Tabs/Client";
import { ContractDetailTabLife } from "../Tabs/ContractDetail";
import Documents from "../Tabs/Documents";
import InsuredEvent from "../Tabs/InsuredEvent";
import Investing from "../Tabs/Investing";
import { PersonsTabLife } from "../Tabs/Persons";
import { TransactionsTabLife } from "../Tabs/Transactions";
import useIsFunds from "../useIsFunds";

export const LifeInsuranceDetail = (): JSX.Element => {
	const contractId = useContractId();
	const isFunds = useIsFunds(contractId);

	return (
		<TabsWithQueryParameter
			appBar={
				<BackNavigationBar title={<LifeTitle />} subtitle={<LifeSubtitle />} />
			}
			queryParamKey="main-tab"
			tabs={[
				{ label: "Smlouva", slug: "smlouva" },
				{ label: "Osoby a krytí", slug: "osoby-kryti" },
				{ label: "Platby", slug: "pohyby" },
				{ label: "Investování", slug: "investovani" },
				{ label: "Klient", slug: "klient" },
				{ label: "Pojistná událost", slug: "pojistna-udalost" },
				{ label: "Dokumenty", slug: "dokumenty" },
			].filter(({ slug }) => {
				// vsechny taby krome investovani zobraz
				if (slug !== "investovani") return true;

				return isFunds;
			})}
		>
			<ContractDetailTabLife />
			<PersonsTabLife />
			<TransactionsTabLife />
			{isFunds && <Investing />}
			<ClientTabLife />
			<InsuredEvent />
			<Documents />
		</TabsWithQueryParameter>
	);
};
