import { useLifeFunds } from "../../queryHooks";

export default function useIsFunds(contractId: string): boolean {
	const result = useLifeFunds({
		id: contractId,
		options: { enabled: !!contractId },
	});

	return result.isSuccess && result.data.length > 0;
}
