import HandCoinsIcon from "../Icons/HandCoins";
import { InternalArrowLink } from "../Link/InternalLink";
import InfoBox, { AlertProps } from ".";

const PayoutBox = ({ ...rest }: AlertProps): JSX.Element => (
	<InfoBox
		title="Kolik dostanu při ukončení smlouvy?"
		action={
			<InternalArrowLink to="/kontakty/">Zobrazit kontakty</InternalArrowLink>
		}
		icon={<HandCoinsIcon sx={{ width: 32, height: 30 }} color="text" />}
		{...rest}
	>
		Neváhejte se na nás obrátit, naši operátoři Vám informaci rádi sdělí.
	</InfoBox>
);

export default PayoutBox;
