import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import Stack from "@mui/material/Stack";

import { NumberToCZK } from "../../../../../functions";
import { dateTimeFormat } from "../../../../../functions/dates";
import cs from "../../../../../translations/cs.json";
import { NotInMVPButton } from "../../../../Drawer/NotInMVP";
import { HeaderWithDescriptionProps } from "../../../../HeaderWithDescription";
import InformationList from "../../../../InformationList";
import { InternalLink } from "../../../../Link";
import { BodyTextM } from "../../../../Typography";
import { CommonCoverage } from "../../../types";
import ShowRiderDetailButton from "./ShowRiderDetailButton";

interface ISecondaryRidersWithData {
	secondaryCoverages?: CommonCoverage[];
	isLoading: boolean;
}

export const SecondaryRidersView = ({
	secondaryCoverages,
	isLoading,
}: ISecondaryRidersWithData): JSX.Element => {
	const ai = useAppInsightsContext();

	const informationRows = secondaryCoverages
		? mapCoveragesToRows(secondaryCoverages)
		: [
				{
					title: "Žádná",
					description: "Pojistník nemá žádná připojištění",
				},
		  ];

	return (
		<Stack gap={4}>
			<InformationList
				isLoading={isLoading}
				title={cs.life.global.supplementaryInsurance}
				information={informationRows}
			/>
			<NotInMVPButton
				disabled={isLoading}
				onClick={() => {
					ai.trackEvent({
						name: `[Change] ${cs.life.personsAndRiders.selected.changeInsuranceAmounts}`,
						properties: {
							type: "not in MVP",
						},
					});
				}}
				text={cs.life.personsAndRiders.selected.changeInsuranceAmounts}
				content={
					<>
						Máte-li zájem o úpravu jednotlivých připojištění (pojistné krytí),
						neváhejte kontaktovat svého poradce. A pokud poradce nemáte,
						zavolejte nám na{" "}
						<InternalLink variant="light" to="tel:+420244090800">
							244 090 800
						</InternalLink>{" "}
						a rádi Vám schůzku domluvíme.
					</>
				}
			/>
		</Stack>
	);
};

const mapCoveragesToRows = (
	coverages: CommonCoverage[]
): HeaderWithDescriptionProps[] => {
	return coverages.map((coverage) => {
		return {
			title: coverage.name,
			description: `${
				coverage.validFrom
					? dateTimeFormat.format(new Date(coverage.validFrom))
					: "-"
			} - ${
				coverage.validTo
					? dateTimeFormat.format(new Date(coverage.validTo))
					: "-"
			}`,
			additionalElements: (
				<Stack
					sx={{
						flexDirection: { md: "row" },
						justifyContent: { md: "space-between" },
						alignItems: "flex-start",
						width: { md: "250px" },
						pl: { md: 3 },
					}}
				>
					<BodyTextM sx={{ width: { md: "105px" }, color: "success.main" }}>
						{NumberToCZK(coverage.ammount)}
					</BodyTextM>
					{coverage.id}
					<ShowRiderDetailButton
						coverageId={coverage.id}
						filePath={coverage.documentPath}
						coverageName={coverage.name}
					/>
				</Stack>
			),
		};
	}) as HeaderWithDescriptionProps[];
};
