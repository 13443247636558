import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { useState } from "react";

import cs from "../../../../../translations/cs.json";
import riders from "../../../../../translations/riders.cs";
import Download from "../../../../Download";
import NoDownload from "../../../../Download/NoDownload";
import SimpleDrawer from "../../../../Drawer/SimpleDrawer";

interface IShowCoverageDetailButton {
	coverageId: string;
	coverageName: string;
	filePath?: string;
}

function ShowRiderDetailButton({
	coverageId,
	coverageName,
	filePath,
}: IShowCoverageDetailButton) {
	const [isOpen, setIsOpen] = useState(false);
	const ai = useAppInsightsContext();
	const coverageDescription = riders[coverageId as keyof typeof riders];
	if (!coverageDescription)
		ai.trackEvent(
			{ name: "No coverage detail found" },
			{ riderId: coverageId }
		);

	return (
		<>
			<Button
				variant="text"
				size="small"
				onClick={() => setIsOpen((oldValue) => !oldValue)}
				sx={{ alignSelf: "flex-start" }}
			>
				{cs.life.personsAndRiders.coverageDetail}
			</Button>
			<SimpleDrawer
				open={isOpen}
				onClose={() => setIsOpen(false)}
				title="Detail krytí"
				isCloseIcon
				width={560}
			>
				<Stack spacing={5}>
					{coverageDescription ?? cs.life.personsAndRiders.withoutCoverage}
					{filePath && filePath.length > 0 ? (
						<Download
							downloadPath={filePath}
							name={coverageName}
							mimeType="PDF"
						/>
					) : (
						<NoDownload />
					)}
				</Stack>
			</SimpleDrawer>
		</>
	);
}
export default ShowRiderDetailButton;
