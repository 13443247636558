import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { Formik, useFormikContext } from "formik";
import { useEffect, useState } from "react";

import {
	ContractPaymentInfo,
	GetLifeDetailResponse,
	LifePaymentInfo,
} from "../../../../../../models";
import { useContractId } from "../../../../../context/ContractIDContext";
import { NumberToCZK } from "../../../../../functions";
import { useLifeDetail } from "../../../../../queryHooks";
import cs from "../../../../../translations/cs.json";
import CorrectPaymentSymbols from "../../../../AttentionBlock/CorrectPaymentSymbols";
import RecurringPayment from "../../../../AttentionBlock/RecurringPayment";
import InfoIconDrawer from "../../../../Drawer/InfoIconDrawer";
import Modal from "../../../../Drawer/Modal";
import { insuranceType } from "../../../../Form/rules";
import Select from "../../../../Form/Select";
import InformationList from "../../../../InformationList";
import QrCodePayment from "../../../../QRCode/QrCodePayment";

interface IPaymentInfoAction {
	paymentInfo: LifePaymentInfo;
}

const PaymentInfoAction = ({ paymentInfo }: IPaymentInfoAction) => {
	const [isOpen, setIsOpen] = useState(false);

	return (
		<>
			<Button size="small" isHorizontal onClick={() => setIsOpen(true)}>
				{cs.global.howToSetRecurringPayment}
			</Button>
			<Modal onClose={() => setIsOpen(false)} open={isOpen}>
				<InfoIconDrawer
					bottomButtonAction={() => setIsOpen(false)}
					title={cs.global.howToSetRecurringPayment}
					description={
						<>
							V internetovém bankovnictví své banky zvolíte „trvalý příkaz k
							úhradě“. V menu může být pod položkou „poslat peníze“ apod. V
							následném formuláři vyplníte číslo účtu, kam budete své penzijní
							spoření posílat <strong>{paymentInfo.accountNumber}</strong> a
							frekvenci <strong>{cs.frequencies[paymentInfo.frequency]}</strong>
							. Dále konstantní <strong>{paymentInfo.constantSymbol}</strong> a
							variabilní <strong>{paymentInfo.variableSymbol}</strong> symbol,
							případně IBAN <strong>{paymentInfo.iban}</strong>. Můžete zadat i
							konečné datum trvalého příkazu.
						</>
					}
				/>
			</Modal>
		</>
	);
};

interface IPaymentInfoCard {
	paymentInfo?: LifePaymentInfo;
	hasQrCode: boolean;
	title: string;
	actionComponent?: JSX.Element;
	isLoading: boolean;
}

export const PaymentInfoCard = ({
	title,
	paymentInfo,
	actionComponent,
	hasQrCode,
	isLoading,
}: IPaymentInfoCard) => {
	return (
		<InformationList
			isLoading={isLoading}
			title={title}
			action={actionComponent}
			information={[
				{
					title: cs.global.bankAccountNumber,
					description: paymentInfo?.accountNumber ?? "-",
				},
				{
					title: cs.global.amount,
					description: paymentInfo?.amount
						? NumberToCZK(paymentInfo.amount)
						: "-",
				},
				{
					title: cs.global.constantSymbol,
					description: paymentInfo?.constantSymbol ?? "-",
				},
				{
					title: cs.global.variableSymbol,
					description: paymentInfo?.variableSymbol ?? "-",
				},
				paymentInfo?.frequency
					? {
							title: cs.global.paymentFrequency,
							description: cs.frequencies[paymentInfo.frequency],
					  }
					: false,
				{
					title: "IBAN",
					description: paymentInfo?.iban ?? "-",
				},
				hasQrCode && {
					title: <></>,
					description: <QrCodePayment {...paymentInfo} />,
				},
			]}
		/>
	);
};

interface IPaymentInfoDetails {
	data: ContractPaymentInfo;
	isLoading: boolean;
}

const PaymentInfoDetails = ({ data, isLoading }: IPaymentInfoDetails) => {
	const { values, initialValues, setFieldValue } = useFormikContext();
	const [selectedPaymentInfo, setSelectedPaymentInfo] = useState(
		data.list.find((paymentInfo) => paymentInfo.type === values.insuranceType)
	);

	useEffect(() =>
		setSelectedPaymentInfo(
			data.list.find((paymentInfo) => paymentInfo.type === values.insuranceType)
		)
	);

	useEffect(
		() =>
			data.list.forEach((list) => {
				if (initialValues.insuranceType === "")
					setFieldValue("insuranceType", list.type);
			}),
		[data]
	);

	return (
		<>
			<PaymentInfoCard
				isLoading={isLoading}
				title={cs.global.participant}
				actionComponent={
					selectedPaymentInfo?.client && (
						<PaymentInfoAction paymentInfo={selectedPaymentInfo.client} />
					)
				}
				paymentInfo={selectedPaymentInfo?.client}
				hasQrCode
			/>
			<PaymentInfoCard
				isLoading={isLoading}
				title={cs.global.employer}
				hasQrCode={false}
				paymentInfo={selectedPaymentInfo?.employer}
			/>
		</>
	);
};

const defaultPaymentInfo = {
	list: [
		{
			type: undefined,
			client: {
				accountNumber: undefined,
				amount: undefined,
				constantSymbol: undefined,
				variableSymbol: undefined,
				frequency: undefined,
				iban: undefined,
			},
			employer: {
				accountNumber: undefined,
				amount: undefined,
				constantSymbol: undefined,
				variableSymbol: undefined,
				frequency: undefined,
				iban: undefined,
			},
		},
	],
};

const PaymentInfoWithData = () => {
	const id = useContractId();

	const result = useLifeDetail<GetLifeDetailResponse>({ id: id });

	const paymentInfo = result.data?.paymentInfo ?? defaultPaymentInfo;

	return (
		<Stack gap={5}>
			<Stack gap={3}>
				<RecurringPayment />
				<CorrectPaymentSymbols />
			</Stack>
			<Formik
				initialValues={{
					insuranceType:
						paymentInfo.list.filter(
							(paymentType) => paymentType.type === "Regular"
						).length > 0
							? "Regular"
							: "", // if regular payment type exists, set it as a default payment type
				}}
				validationSchema={insuranceType}
				onSubmit={() => {
					return;
				}}
			>
				<>
					<Select
						isLoading={result.isLoading}
						name="insuranceType"
						isRequired={true}
						label={cs.life.transactions.chooseInsuranceType}
					>
						{paymentInfo.list.map((value, i) => {
							return (
								<option disabled={!value.type} value={value.type} key={i}>
									{value.type ? cs.insuranceTypes[value.type] : "-"}
								</option>
							);
						})}
					</Select>
					<Stack spacing={5}>
						<PaymentInfoDetails
							data={result.data?.paymentInfo ?? defaultPaymentInfo}
							isLoading={result.isLoading}
						/>
					</Stack>
				</>
			</Formik>
		</Stack>
	);
};

export default PaymentInfoWithData;
