import Stack from "@mui/material/Stack";

import { useContractTypeContext } from "../../../../../context/ContractTypeContext";
import cs from "../../../../../translations/cs.json";
import { ChangeBeneficiariesButtonByLifeContractType } from "../../../../ChangeBeneficiaries";
import BeneficiariesInformationList from "../../../../ChangeBeneficiaries/BeneficiariesInformationList";
import { HeadingL } from "../../../../Typography";
import { CommonLifeContractPerson } from "../../../types";

interface IBeneficiares {
	person: CommonLifeContractPerson;
	isLoading: boolean;
}

function Beneficiaries({ person, isLoading }: IBeneficiares): JSX.Element {
	const { contractType } = useContractTypeContext();

	return (
		<Stack>
			<Stack
				sx={{
					flexDirection: { md: "row" },
					alignItems: { md: "baseline" },
				}}
			>
				<HeadingL withoutScale>
					{contractType
						? cs.product.beneficiaries.changeBeneficiaries[contractType]
						: cs.product.beneficiaries.changeBeneficiaries.default}
				</HeadingL>
			</Stack>
			<Stack spacing={{ xxs: 4, md: 0 }}>
				<BeneficiariesInformationList
					beneficiaries={person.beneficiaries}
					isLoading={isLoading}
				/>
				<ChangeBeneficiariesButtonByLifeContractType />
			</Stack>
		</Stack>
	);
}

export default Beneficiaries;
